import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { StatusCardContentProps } from './types';
import { SkinGoalsSummary, StatusLayout } from './components';
import { ProgressChecklistGuide } from 'components/progress-checklist';
import { useConsultationFlowConfig } from '@customer-frontend/config';
import { isSyncConsultation } from '@customer-frontend/consultation';
import { routes } from 'utils/routes';
import { isToday } from 'date-fns';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { getZendeskRequestUrl } from '@customer-frontend/utils';

export const AwaitingDoctorCardContent = ({
  consultation,
  personalisationInput,
  ...layoutProps
}: StatusCardContentProps): React.ReactElement => {
  const {
    id: consultationId,
    type: problemType,
    requiresPrescriberCall,
    isInNoPickUpQueue,
    latestPractitionerBooking,
  } = consultation;
  const { isSyncExperience } = useConsultationFlowConfig(problemType) ?? {};
  const isSyncConsult = isSyncConsultation(
    isSyncExperience,
    requiresPrescriberCall,
  );
  const { formatDate } = useIntl();
  const history = useHistory();
  const helpLink = getZendeskRequestUrl({ problemType });

  const asyncConsultsEnabled = useFeatureFlagBoolean(
    'FF_ASYNC_CONSULTS_ENABLED',
  );

  let formattedPractitionerBookingString = null;
  if (latestPractitionerBooking) {
    const formattedStartTime = formatDate(
      latestPractitionerBooking.windowStartAt,
      { hour: 'numeric' },
    );
    const formattedEndTime = formatDate(latestPractitionerBooking.windowEndAt, {
      hour: 'numeric',
      timeZoneName: 'short',
    });
    const formattedDay = isToday(
      new Date(latestPractitionerBooking?.windowStartAt),
    )
      ? 'today'
      : formatDate(latestPractitionerBooking.windowStartAt, {
          weekday: 'long',
        });
    formattedPractitionerBookingString = `${formattedStartTime} - ${formattedEndTime} ${formattedDay}`;
  }

  return (
    <StatusLayout {...layoutProps} testId="awaiting-doctor-consultation-card">
      <SkinGoalsSummary skinGoals={personalisationInput.mappedSkinGoals} />
      {isSyncConsult ? (
        <>
          {isInNoPickUpQueue && !latestPractitionerBooking ? (
            <Typography size="medium-paragraph">
              Your practitioner called but wasn’t able to reach you. Let’s make
              sure they call at a time that works for you.
            </Typography>
          ) : (
            <>
              {!asyncConsultsEnabled && (
                <Typography size="medium-paragraph">
                  <strong>Status:</strong> Your practitioner will call you
                  between {formattedPractitionerBookingString}. While you’re
                  waiting for your practitioner to call, feel free to leave
                  questions for them in the chat.
                </Typography>
              )}
              {asyncConsultsEnabled && (
                <>
                  {latestPractitionerBooking ? (
                    <Typography size="medium-paragraph" isBold>
                      <FormattedMessage
                        defaultMessage="Your practitioner will call you between {formattedPractitionerBookingString}."
                        values={{
                          formattedPractitionerBookingString,
                        }}
                      />
                    </Typography>
                  ) : (
                    <Typography size="medium-paragraph" isBold>
                      <FormattedMessage defaultMessage="Expect to receive a messge in consult chat from your practitioner soon (usually within 48 hours)." />
                    </Typography>
                  )}
                  <Typography size="medium-paragraph">
                    <FormattedMessage defaultMessage="If you have questions about your treatment, please send them in the chat. Your practitioner will answer them during your consult." />
                  </Typography>
                  <Typography size="medium-paragraph">
                    <FormattedMessage
                      defaultMessage="If you have any questions about your payment, orders or your consult status, reach out to our <a>customer support team</a>."
                      values={{
                        a: (chunks) => (
                          <a
                            href={helpLink}
                            target="_blank"
                            rel="noreferrer"
                            className="text-link"
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </Typography>
                  <Typography size="medium-paragraph">
                    <FormattedMessage defaultMessage="Please note: Your orders are paused until your consult is complete." />
                  </Typography>
                </>
              )}
            </>
          )}

          <div
            className={
              asyncConsultsEnabled
                ? 'flex flex-col gap-2 w-full'
                : 'flex flex-col gap-2 w-min'
            }
          >
            <Button
              onClick={() => {
                history.push(routes.consultation.chat(consultationId));
              }}
            >
              <FormattedMessage defaultMessage="Open consult chat" />
            </Button>
            {(!asyncConsultsEnabled || latestPractitionerBooking) && (
              <Button
                onClick={() => {
                  history.push(
                    routes.consultation.scheduleCall(consultation.id),
                  );
                }}
              >
                Reschedule call
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="py-2">
          <ProgressChecklistGuide
            source="profile"
            problemType={layoutProps.problemType}
          />
        </div>
      )}
    </StatusLayout>
  );
};
