import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { Maybe, ProblemType } from '@customer-frontend/graphql-types';
import { getZendeskRequestUrl } from '@customer-frontend/utils';
import { Typography } from '@eucalyptusvc/design-system';

type DoctorAssignedCardProps = {
  consultationId: string;
  doctorName: string;
  allowPatientToSendMessageToDoctor: boolean;
  isSyncConsult?: boolean;
  latestPractitionerBooking?: Maybe<{ id: string }>;
  hasChatThread: boolean;
  hasActiveWeightTreatment?: boolean;
  routes: {
    consultation: {
      chat: (id: string, loadAtTop?: boolean) => string;
      review: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
  problemType: ProblemType;
};

export function DoctorAssignedCard({
  consultationId,
  doctorName,
  allowPatientToSendMessageToDoctor,
  isSyncConsult,
  latestPractitionerBooking,
  hasChatThread,
  hasActiveWeightTreatment,
  routes,
  problemType,
}: DoctorAssignedCardProps): ReactElement {
  const history = useHistory();
  const sunsetBpDashboard = useFeatureFlagBoolean('SUNSET_BP_DASHBOARD');

  const helpLink = getZendeskRequestUrl({ problemType });

  const asyncConsultsEnabled = useFeatureFlagBoolean(
    'FF_ASYNC_CONSULTS_ENABLED',
  );

  const chatButton = hasChatThread
    ? {
        key: 'doctorAssignedCardButtonChat',
        text: <FormattedMessage defaultMessage="Open consult chat" />,
        onClick: () => history.push(routes.consultation.chat(consultationId)),
        level: 'primary' as const,
      }
    : {
        key: 'doctorAssignedCardButtonConsult',
        text: asyncConsultsEnabled ? (
          <FormattedMessage defaultMessage="Open consult chat" />
        ) : (
          <FormattedMessage
            defaultMessage="View consult"
            description="Button in profile to open up your consultation to send message to practitioner"
          />
        ),
        onClick: () => history.push(routes.consultation.review(consultationId)),
        level: 'primary' as const,
      };

  const buttons = [];
  if (allowPatientToSendMessageToDoctor) {
    buttons.push(chatButton);
  }
  if (hasActiveWeightTreatment && !sunsetBpDashboard) {
    buttons.push({
      key: 'doctorAssignedCardButtonDashboard',
      text: <FormattedMessage defaultMessage="View dashboard" />,
      onClick: () => {
        history.push(routes.weightLoss.dashboard);
      },
      level: allowPatientToSendMessageToDoctor
        ? ('secondary' as const)
        : ('primary' as const),
    });
  }

  if (isSyncConsult && latestPractitionerBooking) {
    return (
      <ConsultationCardContentView
        paragraphs={
          asyncConsultsEnabled
            ? [
                {
                  key: 'doctorAssignedCardText0',
                  text: (
                    <Typography size="medium-paragraph" isBold>
                      <FormattedMessage
                        defaultMessage="{doctorName} is reviewing your information and will be in touch soon."
                        values={{
                          doctorName,
                        }}
                      />
                    </Typography>
                  ),
                },
                {
                  key: 'doctorAssignedCardText1',
                  text: (
                    <Typography size="medium-paragraph">
                      <FormattedMessage defaultMessage="If you have questions about your treatment, please send them in the chat. Your practitioner will answer them during your consult." />
                    </Typography>
                  ),
                },
                {
                  key: 'doctorAssignedCardText2',
                  text: (
                    <Typography size="medium-paragraph">
                      <FormattedMessage
                        defaultMessage="If you have any questions about your payment, orders or your consult status, reach out to our <a>customer support team</a>."
                        values={{
                          a: (chunks) => (
                            <a
                              href={helpLink}
                              target="_blank"
                              rel="noreferrer"
                              className="text-link"
                            >
                              {chunks}
                            </a>
                          ),
                        }}
                      />
                    </Typography>
                  ),
                },
                {
                  key: 'doctorAssignedCardText3',
                  text: (
                    <Typography size="medium-paragraph">
                      <FormattedMessage defaultMessage="Please note: Your orders are paused until your consult is complete." />
                    </Typography>
                  ),
                },
              ]
            : [
                {
                  key: 'doctorAssignedCardText',
                  text: (
                    <FormattedMessage
                      defaultMessage="{doctorName} is reviewing your information and will call you shortly. {allowPatientToSendMessageToDoctor, select, true {Feel free to leave questions in the chat.} other {}}"
                      description="Description in profile to show that practitioner will call soon and they can open chat page"
                      values={{
                        doctorName,
                        allowPatientToSendMessageToDoctor,
                      }}
                    />
                  ),
                },
              ]
        }
        buttons={buttons}
      />
    );
  }
  return (
    <ConsultationCardContentView
      paragraphs={
        asyncConsultsEnabled
          ? [
              {
                key: 'doctorAssignedCardText0',
                text: (
                  <Typography size="medium-paragraph" isBold>
                    <FormattedMessage
                      defaultMessage="{doctorName} is reviewing your information and will be in touch soon."
                      values={{
                        doctorName,
                      }}
                    />
                  </Typography>
                ),
              },
              {
                key: 'doctorAssignedCardText1',
                text: (
                  <Typography size="medium-paragraph">
                    <FormattedMessage defaultMessage="If you have questions about your treatment, please send them in the chat. Your practitioner will answer them during your consult." />
                  </Typography>
                ),
              },
              {
                key: 'doctorAssignedCardText2',
                text: (
                  <Typography size="medium-paragraph">
                    <FormattedMessage
                      defaultMessage="If you have any questions about your payment, orders or your consult status, reach out to our <a>customer support team</a>."
                      values={{
                        a: (chunks) => (
                          <a
                            href={helpLink}
                            target="_blank"
                            rel="noreferrer"
                            className="text-link"
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </Typography>
                ),
              },
              {
                key: 'doctorAssignedCardText3',
                text: (
                  <Typography size="medium-paragraph">
                    <FormattedMessage defaultMessage="Please note: Your orders are paused until your consult is complete." />
                  </Typography>
                ),
              },
            ]
          : [
              {
                key: 'doctorAssignedCardText',
                text: (
                  <FormattedMessage
                    defaultMessage="{doctorName} is currently going through your information and will be in touch soon."
                    description="Description in profile to show that practitioner is reviewing the quiz answers and will in touch soon"
                    values={{
                      doctorName,
                    }}
                  />
                ),
              },
            ]
      }
      buttons={buttons}
    />
  );
}
